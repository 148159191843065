<script lang="jsx">
import TojoyInnerLayout from '@/components/layout/inner'
export default {
  name: 'behaviour-index-view',

  components: {
    TojoyInnerLayout
  },

  render(h) {
    return (
      <div class="behaviour-index-view">
        <tojoy-inner-layout
          ref="tojoy-inner-layout"
          options={{breadcrumb:true, nav:false}} />
      </div>
    )
  },
}
</script>

<style lang="scss">
.behaviour-index-view {
  height: 100%;
  .errorPage {
    flex: 1;
    width: auto !important;
  }
}
</style>
